.navegacao {
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3rem;
}

.link {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--cor-fonte-principal);
}

.linkDestacado {
  text-decoration: underline;
}
