.container {
  padding: 2rem;
  text-align: center;
}

.titulo {
  font-family: var(--fonte-secundaria);
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--fuchsia);
}

.projects {
  padding: 0 6vw 3.625rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

@media (min-width: 769px) {
  .container {
    padding: 5rem 7.5rem 6.25rem;
  }

  .projects {
    margin-top: 0;
    padding: 2rem 1.5rem 3.625rem;
  }

  .titulo {
    font-size: 3rem;
  }
}
