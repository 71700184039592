.rodape {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--fuchsia);
  color: var(--branco);
  padding: 2rem 0;
  padding-top: 4rem;
}
.author {
  display: flex;
  column-gap: 0.5rem;
  margin-bottom: 2rem;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
}

.link img {
  width: 40px;
  height: 40px;
}
