.header {
  background-color: var(--fuchsia);
}

.hide {
  display: none;
}

.navegacao {
  display: none;
}

.navegacaoMobile {
  color: white;
}

.menuMobile {
  margin: 0 1rem;
  color: white;
}

.link {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--cor-fonte-principal);
}

.linkDestacado {
  text-decoration: underline;
}

@media (max-width: 744px) {
  .navegacao {
    height: 72px;
  }
}

@media (min-width: 1300px) {
  .menuMobile {
    display: none;
  }

  .navegacao {
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    background-color: white;
  }
}
