* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --fuchsia: #fb4570;
  --hot-pink: #fb6b90;
  --cor-fonte-principal: #fb4570;
  --cor-fonte-post: #fb8da0;
  --branco: #fff;
  --fonte-principal: "Poppins", sans-serif;
  --fonte-secundaria: "Roboto", sans-serif;

  font-family: var(--fonte-principal);
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  min-height: 100vh;
}

a,
button,
input,
textarea {
  font: inherit;
  color: inherit;
}

button {
  background-color: transparent;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

h1,
h2,
h3 {
  font-weight: 700;
  line-height: 5rem;
}
