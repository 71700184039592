.project {
  text-align: center;
  width: 250px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 1.5rem;
  transition: transform 0.2s;
}

.project:hover {
  transform: translate(0, -1rem);
}

.capa {
  width: 100%;
  height: 150px;
}

.wrap {
  padding: 10px;
}

.titulo {
  font-family: var(--fonte-secundaria);
  font-size: 1.25rem;
  color: var(--fuchsia);
  line-height: 1.75rem;
  margin: 0.5rem 0 1.75rem;
}

.texto {
  color: grey;
}

@media (min-width: 769px) {
  .project {
    width: 300px;
  }
  .titulo {
    font-weight: 600;
  }
}
