.container {
  padding: 2rem;
  text-align: center;
}

.titulo {
  font-family: var(--fonte-secundaria);
  font-size: 2rem;
  margin-bottom: 2rem;
  color: var(--fuchsia);
  line-height: 3rem;
}

.subtitulo {
  font-family: var(--fonte-principal);
  font-size: 1.5rem;
  color: var(--hot-pink);
  margin-bottom: 2rem;
}

.logo {
  width: auto;
  height: 200px;
}

.experience {
  border: 1px solid white;
  padding: 40px 60px;
  background-color: white;
  border-radius: 12px;
  width: 350px;
}

.experiences {
  padding: 0 6vw 3.625rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: -2.5rem;
}

@media (min-width: 769px) {
  .container {
    padding: 5rem 7.5rem 6.25rem;
    text-align: center;
  }

  .titulo {
    font-size: 3rem;
  }
}
