.banner {
  padding: 2rem;
  background-color: var(--fuchsia);
  color: var(--branco);
  text-align: center;
}

.titulo {
  font-family: var(--fonte-secundaria);
  font-size: 2rem;
  margin-bottom: 7rem;
}

.subtitulo {
  font-family: var(--fonte-principal);
  font-size: 1.3rem;
  color: var(--fuchsia);
}

.logo {
  width: auto;
  height: 120px;
}

.experience {
  border: 1px solid white;
  padding: 2rem;
  background-color: white;
  border-radius: 12px;
  width: 250px;
}

.experiences {
  padding: 0 6vw 3.625rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: -2.5rem;
}

@media (min-width: 769px) {
  .banner {
    padding: 5rem 7.5rem 6.25rem;
  }
  .titulo {
    margin-bottom: 5rem;
    font-size: 3rem;
  }

  .subtitulo {
    font-size: 2rem;
  }

  .experience {
    width: 230px;
  }

  .experiences {
    padding: 0;
  }
}
