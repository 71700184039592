.banner {
  padding: 1rem;
  background-color: var(--fuchsia);
  color: var(--branco);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.banner > .apresentacao {
  max-width: 100%;
  height: auto;
}

.titulo {
  font-family: var(--fonte-principal);
  font-size: 2rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
}

.hi {
  display: block;
}

.paragrafo {
  font-family: var(--fonte-principal);
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  font-weight: normal;
}

.containerButton {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.banner > .containerMinhaFoto {
  max-width: 100%;
  height: auto;
}

.containerMinhaFoto > .minhaFoto {
  max-width: 100%;
  height: auto;
}

@media (min-width: 426px) and (max-width: 1399px) {
  .banner {
    padding: 3rem;
  }
}

@media (min-width: 1400px) {
  .banner {
    padding: 1rem 7.5rem 6.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
  }

  .apresentacao {
    margin: 1rem;
  }

  .titulo {
    font-size: 4rem;
    margin-top: 4.875rem;
    line-height: 4.2rem;
  }

  .paragrafo {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 100;
  }

  .containerButton {
    text-align: left;
  }

  .containerMinhaFoto {
    margin: 1rem;
  }

  .minhaFoto {
    max-width: 100%;
    height: auto;
  }
}
