.buttonMain {
  display: inline-block;
  border-radius: 24px;
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.125rem;
  background-color: var(--hot-pink);
  color: var(--branco);
  border: 3px solid var(--hot-pink);
  cursor: pointer;
  transition: 0.2s;
}

.buttonMain:hover {
  border: 3px solid var(--fuchsia);
}

.buttonMain.lg {
  padding: 1.5rem 2.75rem;
  font-size: 1.5rem;
  line-height: 1.125rem;
}

@media (max-width: 425px) {
  .buttonMain {
    width: 100%;
  }
}
